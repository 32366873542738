import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth_service';

@Injectable()
export class AuthGuard  {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hasAccess = this.auth.hasAccesUrl(state.url);
    if (hasAccess) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/']);
      return false;
    }
  }
}
