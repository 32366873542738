import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'categorySearchFilter'})
export class CategorySearchFilter implements PipeTransform {
  public transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }

    let filterItems = items;
    if (search != undefined && search != '') {
      search = search.toUpperCase();
      const searchItems = search.split(/[\s,]+/);
      filterItems = items.filter(i =>
        searchItems.every(el => i.room_category_cd.toUpperCase().includes(el)) ||
        searchItems.every(el => i.room_category_name.toUpperCase().includes(el))
      );
    }

    return filterItems;
  }
}
