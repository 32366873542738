
<!-- 
<input 
    class="pl-input date-input" 
    placeholder="Select a date" 
    angular-mydatepicker 
    name="mydate"
    [(ngModel)]="dateModel" 
    #dp="angular-mydatepicker" 
    (dateChanged)="onDateChanged($event)"
    [disabled]="!this.authService.hasAccess('ADMIN_PROMO', true)"
    
    />
<button type="button" class="btn btn-default date-input-btn1" (click)="dp.clearDate()"
 [disabled]="!this.authService.hasAccess('ADMIN_PROMO', true)">
<i class="glyphicon glyphicon-remove"></i>
</button>
<button type="button" class="btn btn-default date-input-btn2" (click)="dp.toggleCalendar()"
 [disabled]="!this.authService.hasAccess('ADMIN_PROMO', true)">
<i class="glyphicon glyphicon-calendar"></i>
</button> -->



	<div class="row col-md-12">
            <label class="pl-label">
                EXPIRES ON
              </label>

			<input

                class="pl-input date-input"
				placeholder="Select a date"
				name="dp"
				[(ngModel)]="model"
				ngbDatepicker
				#dp="ngbDatepicker"
			/>
			
            <!-- [disabled]="!this.authService.hasAccess('ADMIN_PROMO', true)" -->
            
            <!-- <button 
                type="button" 
                class="btn btn-default date-input-btn1" 
                (click)="dp.clearDate()">
                <i class="glyphicon glyphicon-remove"></i>
           </button> -->

            <button 
                class="btn btn-default date-input-btn3"
                (click)="dp.toggle()" 
                type="button">
            
                <i class="glyphicon glyphicon-calendar"></i>
            
            </button>
		</div>


<!-- <hr />
<pre>Model: {{ model | json }}</pre>

<ngb-alert class="mt-3 mb-0" type="info" [dismissible]="false">
	You must provide the icon for the button that matches your application's style. In this example, the icon comes from
	<code>bootstrap-icons</code>.
</ngb-alert> -->