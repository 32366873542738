import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'categoryClassFilter'})
export class CategoryClassFilterPipe implements PipeTransform {
  public transform(items: any[], field: string, value: string, quickClickSearch: string): any[] {

    let returnItems = items;

    if (!items) {
      return [];
    }
    if (value === 'ALL') {
      let tempItems = [];
      items.forEach(function (i) {
        // remove dedup procedure
        if (tempItems.find(el => el.room_category_id == i.room_category_id) == undefined) {
          tempItems.push(i);
        }
      });
      returnItems = tempItems;
    } else {
      returnItems = items.filter(i => i[field] == value);
    }

    // prior: room_type_idn, room_type_name
    if (quickClickSearch !== undefined) {
      returnItems = returnItems.filter(i =>
        i['room_category_cd'].toUpperCase().includes(quickClickSearch.toUpperCase())
        || i['room_category_name'].toUpperCase().includes(quickClickSearch.toUpperCase())
      );
    }

    return returnItems;
  }
}
