import {throwError as observableThrowError} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

  public token: string;
  public userName: string;

  private _jwtHelper = new JwtHelperService();


  private domain: string;
  private _url: string; 
  private _loginUrl: string;   

  constructor(private http: HttpClient) {
    this.http = http;
    this.domain = environment.domain;
    this._url = this.domain + 'auth/token';
    this._loginUrl = this.domain + 'auth/token';
  }

  public login(username, password) {
    // if (this.isLoggedIn()) return;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .post(
        this._loginUrl,
        JSON.stringify({'userName': username, 'password': password}),
        {headers: headers}
      ).pipe(
        map(
          response => {

            const d = response;
            if (d['jwt']) {
              this.storeToken(d);
            }

            return d;
          }));
    // .catch(this.handleError);
  }


  public isLoggedIn() {
    return (localStorage.getItem('id_token'));
  }


  twoFactorValidation(username: string, userId: string, token: string): any {
    const parameters = { id: userId, token: token };
    const body = JSON.stringify(parameters);
    // const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authenticationService.token);

    return this.http.post( this._loginUrl + 'auth/twofactor_validate', body)
      .pipe(map((response: Response) => {
        const token = response['jwt'];
        if (token) {
          // set token property
          this.token = token;
          this.userName = username;
          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token, property: null, propList: [] }));

          // return true to indicate successful login
          return response;
        } else {
          // return false to indicate failed login
          return response;
        }
      }));
  }


  public storeToken(token) {
    localStorage.setItem('id_token', token.jwt);
    localStorage.setItem('XSRF-TOKEN', token['xsrf-token']);
    localStorage.setItem('user', JSON.stringify(this._jwtHelper.decodeToken(token.jwt)));

  }

  public getTokenInfo() {
    return JSON.parse(localStorage.getItem('user'));
  }

  public getPerms() {
    return this.getTokenInfo() ? this.getTokenInfo()['perm'] : [];
  }

  public isAdmin() {
    let admin = false;

    try {
      const perms = this.getPerms();
      if (!perms || perms.length === 0) {
        return admin;
      }
      perms.forEach((e) => {
        if (e['name'].search('UPSELL_') > -1) {
          const authName = e['name'].replace('UPSELL_', '');
          if (authName.substr(0, 5) === 'ADMIN') {
            admin = true;
          }
        }

      });
    } finally {
    }

    return admin;
  }

  public isAgent() {
    let agent = false;

    try {
      const perms = this.getPerms();
      if (!perms) {
        return agent;
      }
      perms.forEach((e) => {
        if (e['name'].search('UPSELL_') > -1) {
          const authName = e['name'].replace('UPSELL_', '');
          if (authName.substr(0, 5) === 'AGENT') {
            agent = true;
          }
        }

      });
    } finally {
    }

    return agent;
  }

  public defaultMode(): string {
    const mode_fd = this.hasMode('fd_user');
    const mode_cc = this.hasMode('cc_user');

    if (mode_cc) {
      return 'cc_user';
    } else {
      return 'fd_user';
    }
  }

  public hasMode(mode: string) {
    let access = false;
    const modes = this.getTokenInfo() ? this.getTokenInfo()['mode'] : undefined;

    if (!modes) {
      return mode === 'fd_user';
    }

    if (modes[mode] != undefined) {
      access = modes[mode];
    }

    return access;
  }

  public hasAccess(module: string, edit: boolean) {
    let access = false;

    module = module.replace('UPSELL_', '');
    try {
      const perms = this.getPerms();
      if (!perms) {
        return access;
      };

      perms.forEach((e) => {

        if (e['name'].search('UPSELL_') > -1) {

          const authName = e['name'].replace('UPSELL_', '')
          
          if (module !== 'ADMIN_GENERAL') {
            if (authName === module) {
              access = true;
              if (edit && e['access'] !== 2) {
                access = false;
              }
            }
          } else {
            if (authName === 'ADMIN_TENANT' || authName === 'ADMIN_PROP') {
              access = true;
              if (edit && e['access'] !== 2) {
                access = false;
              }
            }
          }

        }

      });
    } finally {
    }

    return access;
  }

  public firstAgentRoute() {
    let url = '';

    try {
      const perms = this.getPerms();
      if (!perms) {
        return url;
      }

      
      perms.forEach((e) => {
        if (e['name'].search('UPSELL_') > -1) {
          const authName = e['name'].replace('UPSELL_', '');
          if (authName.substr(0, 5) === 'AGENT') {
            switch (authName) {
              case 'AGENT_TEAMSTATS':
                url = '/teamstats';
                break;
              case 'AGENT_FUTURE':
                url = '/prearrival';
                break;
              case 'AGENT_UPSELL':
                url = '/reservation';
                break;
            }
          }
        }
      });
    } finally {
    }

    return url;
  }

  public firstAdminRoute() {
    let url = '';

    try {
      const perms = this.getPerms();
      if (!perms) {
        return url;
      }
      let reporting = false;
      perms.forEach((e) => {
        if (e['name'].search('UPSELL_') > -1) {
          const authName = e['name'].replace('UPSELL_', '');
          if (authName.substr(0, 5) === 'ADMIN') {
            switch (authName) {
              case 'ADMIN_REPORTING':
                reporting = true;
                url = '/admin/reporting';
                break;
              case 'ADMIN_GENERAL':
              case 'ADMIN_TENANT':
              case 'ADMIN_PROP':
                url = '/admin/general';
                break;
              case 'ADMIN_INVENTORY':

                url = '/admin/remaining';
                break;
              case 'ADMIN_PRICING':
                url = '/admin/default_bar';
                break;
              case 'ADMIN_PRICING_OVRD':
                url = '/admin/bar';
                break;
              case 'ADMIN_PROMO':
                url = '/admin/promo';
                break;
              case 'ADMIN_USERS':
                url = '/admin/users';
                break;
            }
          }
        }
      });
      if (reporting) {
        url = '/admin/reporting';
      }
    } finally {
    }

    return url;
  }

  public hasAccesUrl(url: string) {
    let access = false;

    const urlMapping = [
      {url: '/admin/general', module: 'ADMIN_GENERAL'},
      {url: '/admin/exclusions', module: 'ADMIN_GENERAL'},
      {url: '/admin/inventory', module: 'ADMIN_INVENTORY'},
      {url: '/admin/allocation', module: 'ADMIN_INVENTORY'},
      {url: '/admin/remaining', module: 'ADMIN_INVENTORY'},
      {url: '/admin/allocation_default', module: 'ADMIN_INVENTORY'},
      {url: '/admin/minimum', module: 'ADMIN_INVENTORY'},
      {url: '/admin/alerts', module: 'ADMIN_INVENTORY'},
      {url: '/admin/default_bar', module: 'ADMIN_PRICING'},      
      
      {url: '/admin/bar', module: 'ADMIN_PRICING_OVRD'},
      
      {url: '/admin/anchor', module: 'ADMIN_PRICING'},
      {url: '/admin/anchor_ovrd', module: 'ADMIN_PRICING_OVRD'},
      {url: '/admin/default_limits', module: 'ADMIN_PRICING'},
      {url: '/admin/default_min', module: 'ADMIN_PRICING'},
      {url: '/admin/default_max', module: 'ADMIN_PRICING'},
      {url: '/admin/default_ofr', module: 'ADMIN_PRICING'},
      {url: '/admin/default_los', module: 'ADMIN_PRICING'},
      {url: '/admin/default_toa', module: 'ADMIN_PRICING'},
      {url: '/admin/default_adu', module: 'ADMIN_PRICING'},
      {url: '/admin/disc', module: 'ADMIN_PRICING'},
      {url: '/admin/disc_min', module: 'ADMIN_PRICING_OVRD'},
      {url: '/admin/disc_max', module: 'ADMIN_PRICING_OVRD'},
      {url: '/admin/promo', module: 'ADMIN_PROMO'},
      {url: '/admin/reporting', module: 'ADMIN_REPORTING'},
      {url: '/admin/users', module: 'ADMIN_USERS'}
    ];

    urlMapping.forEach((e) => {
      if (e['url'] === url) {
        access = this.hasAccess(e['module'], false);
      }
    });

    return access;
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }
}
