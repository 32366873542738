import {Routes} from '@angular/router';
import {AuthGuard} from './services/auth_guard_service';

export const appRoutes: Routes = [

  {path: '', loadChildren: () => import('app/modules/auth/login/login.module').then(m => m.AuthLoginModule)},
  {path: 'hotels', loadChildren: () => import('app/modules/auth/hotel/hotel.module').then(m => m.AuthHotelModule)},
  {path: 'maintenance', loadChildren: () => import('app/modules/auth/maintenance/maintenance.module').then(m => m.AuthMaintenanceModule)},
  {path: 'recovery/:token_id', loadChildren: () => import('app/modules/auth/recovery/recovery.module').then(m => m.AuthRecoveryModule)},

  {path: 'activity', loadChildren: () => import('app/modules/agent/activity/activity.module').then(m => m.AgentActivityModule)},
  {path: 'stats', loadChildren: () => import('app/modules/agent/stats/stats.module').then(m => m.AgentStatsModule)},
  {path: 'teamstats', loadChildren: () => import('app/modules/agent/team-stats/team-stats.module').then(m => m.AgentTeamStatsModule)},
  {path: 'profile', loadChildren: () => import('app/modules/agent/profile/profile.module').then(m => m.AgentProfileModule)},

  {
    path: 'confirm',
    loadChildren: () => import('app/modules/agent/home/confirmation/confirmation.module').then(m => m.AgentConfirmationModule)
  },
  {
    path: 'confirmation/:id/:confirmation',
    loadChildren: () => import('app/modules/agent/home/confirmation-verify/confirmation-verify.module').then(m => m.AgentConfirmationVerifyModule)
  },
  {
    path: 'reservation/:prop_id',
    loadChildren: () => import('app/modules/agent/home/reservation/reservation.module').then(m => m.AgentReservationModule)
  },

  {
    path: 'generic/:prop_id',
    loadChildren: () => import('app/modules/agent/home/home.module').then(m => m.AgentHomeModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'callcenter/:prop_id',
    loadChildren: () => import('app/modules/agent/call-center/call-center.module').then(m => m.AgentCallCenterModule),
    runGuardsAndResolvers: 'always'
  },
  { 
    path: 'prearrival/:prop_id',
    loadChildren: () => import('app/modules/agent/call-center/call-center.module').then(m => m.AgentCallCenterModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'direct/:prop_id',
    loadChildren: () => import('app/modules/agent/call-center/call-center.module').then(m => m.AgentCallCenterModule),
    runGuardsAndResolvers: 'always'
  },  
  {
    path: 'addon/:prop_id',
    loadChildren: () => import('app/modules/agent/call-center/call-center.module').then(m => m.AgentCallCenterModule),
    runGuardsAndResolvers: 'always'
  },

  {path: 'offer_generic/:id', loadChildren: () => import('app/modules/agent/offer/offer.module').then(m => m.AgentOfferModule)},
  {path: 'offer_generic', loadChildren: () => import('app/modules/agent/offer/offer.module').then(m => m.AgentOfferModule)},

  {path: 'admin', loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},

  /*{path: 'guest/:res_id', loadChildren: () => import('app/modules/guest/offer/offer.module').then(m => m.GuestOfferModule)},
  {path: 'guest', loadChildren: () => import('app/modules/guest/offer/offer.module').then(m => m.GuestOfferModule)},*/

];
