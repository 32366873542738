import {Injectable} from '@angular/core';
import {config} from '../config';
import {Httpclient} from './http_client';
import { environment } from '../../environments/environment';

@Injectable()
export class Reporting {

  private domain: string;
  private _url: string; 

  constructor(private httpClient: Httpclient) {
    this.httpClient = httpClient;
    this.domain = environment.domain;
    this._url = this.domain + 'admin/';
  }

  getDateRange(hotel: any, dateRange: string) {
    let url = this._url + hotel + '/date_range';

    url = url + '?date_range=' + dateRange;

    return this.httpClient.get(url);
  }

  getAgent(hotel: any, mode: string, dateRange: string, 
    dt_start: string, dt_end: string, byCheckout: boolean,
    flgAddon: boolean
    ) {
    let url = this._url + hotel + '/reporting/agent_performance';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&mode=' + mode;

    if (byCheckout) {
      url = url + '&byCheckout=' + byCheckout;
    }

    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getRoom(hotel: any, mode: string, dateRange: string, 
          dt_start: string, dt_end: string, authUserByID: boolean, 
          byCheckout: boolean, flgAddon: boolean) {
    let url = this._url + hotel + '/reporting/room_category_performance';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&mode=' + mode;

    if (byCheckout) {
      url = url + '&byCheckout=' + byCheckout;
    }

    url = url + '&authUserByID=' + authUserByID;

    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getAgentCommission(hotel: any, mode: string, dateRange: string, dt_start: string, dt_end: string
                     , byCheckout: boolean, properties: string, byfdmode: boolean, byccmode: boolean
                     , bydmode: boolean, includeNonAccepted: boolean, includedDeleted: boolean
                     , flgAddon: boolean) {
    let url = this._url + hotel + '/reporting/agent_commission';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&mode=' + mode;

    if (byCheckout) {
      url = url + '&byCheckout=' + byCheckout;
    }

    url = url + '&properties=' + properties;
    url = url + '&byfdmode=' + byfdmode;
    url = url + '&byccmode=' + byccmode;
    url = url + '&bydmode=' + bydmode;

    if (includeNonAccepted) {
      url = url + '&includeNonAccepted=' + includeNonAccepted;
    }
    if (includedDeleted) {
      url = url + '&includedDeleted=' + includedDeleted;
    }

    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getPropertyPerformance(hotel: any, 
                         mode: string, 
                         dateRange: string, 
                         dt_start: string, 
                         dt_end: string, 
                         byCheckout: boolean, 
                         flgAddon: boolean
                         ) {
    let url = this._url + hotel + '/reporting/propety_performance';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&mode=' + mode;

    if (byCheckout) {
      url = url + '&byCheckout=' + byCheckout;
    }

    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getAgentList(hotel: any) {
    const url = this._url + hotel + '/reporting/agent_list';

    return this.httpClient.get(url);
  }

  getAgentActivity(
    hotel: any,
    mode: string,
    dateRange: string,
    dt_start: string,
    dt_end: string,
    byCheckout: boolean,
    auth_user_id: string,
    conf_num: string,
    pendingApproval: boolean,
    pendingReview: boolean,     
    wasUpsell: boolean,
    hasTagNote: boolean,
    authUserByID: boolean,
    page: number,
    sortDesc: boolean,
    sortByStay: boolean,
    tag_id: string,
    flgAddon: boolean) {
    let url = this._url + hotel + '/reporting/agent_activity';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;

    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&mode=' + mode;

    if (byCheckout) {
      url = url + '&byCheckout=' + byCheckout;
    }

    if (auth_user_id) {
      url = url + '&auth_user_id=' + auth_user_id;
    }
    if (conf_num) {
      url = url + '&conf_num=' + conf_num;
    }
    if (pendingApproval) {
      url = url + '&pendingApproval=' + pendingApproval;
    }
    if (pendingReview) {
      url = url + '&pendingReview=' + pendingReview;
    }
    if (wasUpsell) {
      url = url + '&wasUpsold=' + wasUpsell;
    }
    if (hasTagNote) {
      url = url + '&hasTagNote=' + hasTagNote;
    }
    if (tag_id) {
      url = url + '&tag_id=' + tag_id;
    }

    url = url + '&authUserByID=' + authUserByID;
    url = url + '&page=' + page;
    url = url + '&sortDesc=' + sortDesc;
    url = url + '&sortByStay=' + sortByStay;

    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getArrivalActivity(hotel: any, 
                    arrivalMode: string, 
                    dateRange: string,
                    dt_start: string, 
                    dt_end: string, 
                    flgAddon: boolean
                    ) {
    let url = this._url + hotel + '/reporting/arrival_activity';

    if (dt_start && dt_start.length > 10) {
      const dt = new Date(dt_start);
      dt_start = dt.getFullYear() + '-' + String((dt.getMonth() + 1)).padStart(2, '0') + '-' + String(dt.getDate()).padStart(2, '0');
    }
    if (dt_end && dt_end.length > 10) {
      const dt = new Date(dt_end);
      dt_end = dt.getFullYear() + '-' + String((dt.getMonth() + 1)).padStart(2, '0') + '-' + String(dt.getDate()).padStart(2, '0');
    }

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;

      if (dt_end) {
        url = url + '&dt_end=' + dt_end;
      }
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&arrival_mode=' + arrivalMode;


    if (flgAddon === true || flgAddon === false) {
      url = url + '&flg_addon=' + flgAddon;
    }

    return this.httpClient.get(url);
  }

  getBookingActivity(
    hotel: any,
    arrivalMode: string,
    dateRange: string,
    dt_start: string,
    dt_end: string) {
    let url = this._url + hotel + '/reporting/booking_activity';

    if (dateRange !== 'CUSTOM') {
      url = url + '?date_range=' + dateRange;

      if (dt_end) {
        url = url + '&dt_end=' + dt_end;
      }
    } else {
      url = url + '?dt_start=' + dt_start + '&dt_end=' + dt_end;
    }

    url = url + '&arrival_mode=' + arrivalMode;

    return this.httpClient.get(url);
  }

  getAgentActivityRecent(hotel: any, mode: any) {
    let url = this._url + hotel + '/reporting/agent_activity_recent';
    if (mode) {
      url = url + '?mode=' + mode;
    }
    return this.httpClient.get(url);
  }

  updateOfferProcessedManually(hotel: string, offerSetId: string, processedManually: boolean) {
    const url = this._url + hotel + '/reporting/manualprocessing/' + offerSetId + '?flg_processed_manually=' + processedManually;

    const body = JSON.stringify({
    });

    return this.httpClient.put(url, body);
  }

  getActivityByConfNum(hotel: any, confNum: any) {
    let url = this._url + hotel + '/reporting/activity_conf_num';

    url = url + '?conf_num=' + confNum;

    return this.httpClient.get(url);
  }

  getActivityByOfferSetID(hotel: any, offerSetId: any) {
    let url = this._url + hotel + '/reporting/activity_offsetid';

    url = url + '?offer_set_id=' + offerSetId;

    return this.httpClient.get(url);
  }


  getRoomCategories(hotel: any) {
    let url = this._url + hotel + '/reporting/getroomcategories';
    return this.httpClient.get(url);
  }


  setAgentActivity(
    hotel: any,
    offer_set_id: string,
    dt_arrival: string,
    los: number,
    base_room_category_id: string,
    upsell_room_category_id: string,
    ofr_ovrd_price: number,
    conf_num: string,
    tag: string,
    tag_id: string,
    notes: string,
    ofr_origin: string,
    auth_user_id: string) {
    const url = this._url + hotel + '/reporting/agent_activity/' + offer_set_id;
    const body = JSON.stringify({
      'dt_arrival': dt_arrival,
      'los': los,
      'base_room_category_id': base_room_category_id,
      'upsell_room_category_id': upsell_room_category_id,
      'ofr_ovrd_price': ofr_ovrd_price,
      'conf_num': conf_num,
      'tag': tag,
      'tag_id': tag_id,
      'notes': notes,
      'ofr_origin': ofr_origin,
      'auth_user_id': auth_user_id
    });

    return this.httpClient.put(url, body);
  }

  createAgentActivity(
    hotel: any,
    dt_arrival: string,
    los: number,
    base_room_category_id: string,
    upsell_room_category_id: string,
    ofr_ovrd_price: number,
    conf_num: string,
    tag: string,
    tag_id: string,
    notes: string,
    ofr_origin: string,
    auth_user_id: string,
    flg_addon: boolean  
  ) {
    const url = this._url + hotel + '/reporting/agent_activity';
    const body = JSON.stringify({
      'dt_arrival': dt_arrival,
      'los': los,
      'base_room_category_id': base_room_category_id,
      'upsell_room_category_id': upsell_room_category_id,
      'ofr_ovrd_price': ofr_ovrd_price,
      'conf_num': conf_num,
      'tag': tag,
      'tag_id': tag_id,
      'notes': notes,
      'ofr_origin': ofr_origin,
      'auth_user_id': auth_user_id,
      'flg_addon': flg_addon
    });

    return this.httpClient.post(url, body);
  }

  setAgentActivityApproval(
    hotel: any,
    offer_set_id: string,
    flg_approved: boolean,
    approval_notes: string) {
    const url = this._url + hotel + '/reporting/agent_activity_approve/' + offer_set_id;
    const body = JSON.stringify({
      'flg_approved': flg_approved,
      'approval_notes': approval_notes
    });

    return this.httpClient.put(url, body);
  }

  setAgentActivityApproveReversal(
    hotel: any,
    offer_set_id: string,
    reversal_notes: string) {
    const url = this._url + hotel + '/reporting/agent_activity_approve_reversal/' + offer_set_id;
    const body = JSON.stringify({
      'reversal_notes': reversal_notes
    });

    return this.httpClient.put(url, body);
  }

  delAgentActivity(
    hotel: any,
    offer_set_id: string) {
    const url = this._url + hotel + '/reporting/agent_activity/' + offer_set_id;

    return this.httpClient.delete(url);
  }

  setRequestApproval(
    hotel: any,
    offer_set_id: string,
    dt_arrival: string,
    los: number,
    base_room_category_id: string,
    upsell_room_category_id: string,
    ofr_ovrd_price: number,
    conf_num: string,
    tag: string,
    tag_id: string,
    notes: string,
    // ofr_origin: string,
    auth_user_id: string,
    flg_processed_manually: boolean,
    flg_rejected_by_prop: boolean
    
    ) {
    const url = this._url + hotel + '/reporting/request_approval/' + offer_set_id;
    const body = JSON.stringify({
      'dt_arrival': dt_arrival,
      'los': los,
      'base_room_category_id': base_room_category_id,
      'upsell_room_category_id': upsell_room_category_id,
      'ofr_ovrd_price': ofr_ovrd_price,
      'conf_num': conf_num,
      'tag': tag,
      'tag_id': tag_id,
      'notes': notes,
      // 'ofr_origin': ofr_origin,
      'auth_user_id': auth_user_id,
      'flg_processed_manually': flg_processed_manually,
      'flg_rejected_by_prop': flg_rejected_by_prop

    });

    return this.httpClient.put(url, body);
  }


  // getActivityByOfferSetID(hotel: any, offerSetId: any) {
  //   let url = this._url + hotel + '/reporting/activity_offsetid';

  //   url = url + '?offer_set_id=' + offerSetId;

  //   return this.httpClient.get(url);
  // }


}

