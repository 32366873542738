import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TitleCasePipe} from '../pipes/title-case.pipe';
import {CategoryClassFilterPipe} from '../pipes/category-class.pipe';
import {CategorySearchFilter} from '../pipes/category-search.pipe';
import {OrderByPipe} from '../pipes/order-by.pipe';
import {OrderPipe} from '../pipes/order.pipe';
import {OfferSearchFilter} from '../pipes/offer-search.pipe';
import {UserSearchFilter} from '../pipes/user-search.pipe';
import {OnlyNumberDirective} from '../directives/only_number';
import {EqualValidator} from '../directives/equal_validator';
import {UiSwitchModule} from 'ngx-ui-switch';

import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {DropdownComponent} from '../directives/dropdown/dropdown.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {DropdownTriggerForDirective} from '../directives/dropdown/dropdown-trigger-for.directive';
import { DatePickerComponent } from '../modules/shared/date-picker/date-picker.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    DatePickerComponent,
    NgSelectModule,
    HttpClientModule,
    OverlayModule,
    NgbTooltipModule,
    NgbDropdownModule


  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TitleCasePipe,
    CategoryClassFilterPipe,
    CategorySearchFilter,
    OrderByPipe,
    OrderPipe,
    OfferSearchFilter,
    UserSearchFilter,
    OnlyNumberDirective,
    EqualValidator,
    UiSwitchModule,
    DatePickerComponent,
    NgbDatepickerModule,
    NgSelectModule,
    HttpClientModule,
    DropdownComponent,
    DropdownTriggerForDirective,
    NgbTooltipModule,
    NgbDropdownModule

  ],
  declarations: [
    TitleCasePipe,
    CategoryClassFilterPipe,
    CategorySearchFilter,
    OrderByPipe,
    OrderPipe,
    OfferSearchFilter,
    UserSearchFilter,
    OnlyNumberDirective,
    EqualValidator,
    DropdownComponent,
    DropdownTriggerForDirective
  ],
  providers: []
})
export class SharedModule {
}
