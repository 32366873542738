import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {appRoutes} from './app.routing';

import {SharedModule} from './shared/shared.module';

import {AuthGuard} from './services/auth_guard_service';
import {AuthService} from './services/auth_service';
import {Httpclient} from './services/http_client';
import {Reporting} from './services/reporting';
import {Shared} from './services/shared';
import {GlobalErrorHandler} from './shared/shared.globalerror';
import {HttpClientXsrfModule} from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
    NgbModule,
    SharedModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],

  bootstrap: [AppComponent],
  providers: [Shared, AuthGuard, AuthService, Httpclient, Reporting, {provide: ErrorHandler, useClass: GlobalErrorHandler}]
})
export class AppModule {
}
