import {AfterContentChecked, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './services/auth_service';
import {Shared} from './services/shared';

import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {finalize, takeWhile} from 'rxjs/operators';
import {Reporting} from './services/reporting';
import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import Utils from './utils/utils';


@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html',
  providers: [AuthService],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {

  modeList: any[] = [];
  user_mode: string;
  username: string;

  @Input('hide-arrow') hideArrow: boolean = true;

  @ViewChild('changePassword') changePasswordSelect: NgSelectComponent;
  changePasswordItems = [{id: 1, text: 'Change Password'}]

  currentSelect: string;
  hotels: any[] = [];
  conf_enabled: boolean;
  show_path_panel: boolean = false;
  show_hotel_dropdown: boolean;
  admin: boolean;
  agent: boolean;
  is_frontend_route: boolean = true;
  is_admin_route: boolean = false;
  is_guest_route: boolean = false;
  isLoading: boolean;
  is_admin_general: boolean;

  show_mode: boolean = false;

  lastUpsellConf: any = undefined;

  // upload status
  timerSubscription: any;
  alive: boolean = true;
  interval: number = 3000; // 3000 = 3 seconds

  sharedSubjectSubscription: any;

  @ViewChild('confirmManuallyUpdatedModal') confirmManuallyUpdatedModal;
  confirmManuallyUpdatedModalReference: any;

  constructor(
    private authService: AuthService, 
    public shared: Shared, 
    private router: Router, 
    private route: ActivatedRoute, 
    private reporting: Reporting, 
    private modalService: NgbModal) {
    
    this.isLoading = false;

    this.shared.config = this.router.config;
    this.show_mode = false;

    if (this.router.url.indexOf('/callcenter') !== -1) {
      this.shared.mode_current = 'cc_user';
    }

  }

  // This is called on app.component.html -> <router-outlet (activate)="updateDropDowns()"></router-outlet> -> to insure dropdown lists are loaded
  updateDropDowns(){

    if(this.router.url !== '/hotels') {
      this.username = this.shared.username;
      let hotels = []
  
      this.shared.availableHotels.forEach(e => {
        hotels.push({id: e.prop_id, text: Utils.toTitleCase(e.prop_name)});
      });
      this.hotels = hotels.sort((a, b) => a.text.localeCompare(b.text))
      this.currentSelect = Utils.toTitleCase(this.shared.selectedHotel);

      let modes = []
      this.shared.availableModes.forEach(e => {
        modes.push({id: e[0], text: this.shared.getModeName(e[0])});
      });
      this.modeList = modes;

    }
  }

  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events.subscribe(event => {

      this.is_frontend_route = true;
      this.is_admin_route = false;
      this.is_guest_route = false;

      if (event instanceof NavigationStart) {

        if (this.sharedSubjectSubscription)
          this.sharedSubjectSubscription.unsubscribe();

        switch (event.url.substr(0, 8)) {
          case '/reserva':
          case '/confirm':
          case '/generic':
          case '/callcen':
          case '/prearri':
            this.updateRecentUpsell();
            this.startUploadPolling();
            break;
        }

        switch (event.url) {
          case '/':
          case '/profile':
          case '/recovery':
          case '/maintenance':
          case '/hotels':
            break;
          default:
            if (event.url.substr(0, 9) != '/recovery'
                && event.url.substr(0, 6) != '/guest') {
              this.startLogOutPolling();
            }
            break;
        }

        if (event.url.substr(0, 6) == '/guest') {
          localStorage.clear();
          this.shared.reset(true);
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.alive = false; // switches TimerObservable off
    this.sharedSubjectSubscription.unsubscribe();
    this.timerSubscription.unsubscribe();

    if (this.confirmManuallyUpdatedModalReference) {
      this.confirmManuallyUpdatedModalReference.close();
    }

    this.modalService.dismissAll();
  }

  ngAfterContentChecked() {

    if (this.router.url.length === 1)
      return;

    if (this.router.url.indexOf('admin') !== -1) {
      this.is_frontend_route = false;
      this.is_admin_route = true;
    } else {
      this.is_frontend_route = true;
      this.is_admin_route = false;
    }

    if (this.router.url.indexOf('guest') !== -1) {
      this.is_guest_route = true;

      this.show_hotel_dropdown = false;
      this.show_path_panel = false;
      this.is_admin_route = false;
      this.is_frontend_route = false;
      return;
    }




    // Remove the links on the offer/hotel select page
    this.show_path_panel = this.router.url.indexOf('offer') !== -1 ?
      false
      : this.router.url.indexOf('hotels') !== -1 ?
        false
        : this.router.url.indexOf('admin') !== -1 ?
          false
          : this.router.url.indexOf('profile') === -1;

    this.show_hotel_dropdown = this.router.url.indexOf('hotels') !== -1 ? false : true;



    if (this.router.url.indexOf('/activity') !== -1) {
      this.shared.currentAgentView = 'activity';
    }
    if (this.router.url.indexOf('/stats') !== -1) {
      this.shared.currentAgentView = 'stats';
    }
    if (this.router.url.indexOf('/callcenter') !== -1) {
      this.shared.currentAgentView = 'callcenter';
      if(this.shared.mode_current !== 'd_user'){
        this.shared.mode_current = 'cc_user';
      }
    }
    if (this.router.url.indexOf('/direct') !== -1) {
      this.shared.currentAgentView = 'direct';
    }
    if (this.router.url.indexOf('/teamstats') !== -1) {
      this.shared.currentAgentView = 'teamstats';
    }
    if (this.router.url.indexOf('/generic') !== -1) {
      this.shared.currentAgentView = 'checkin';
      if (this.shared.checkInState !== 'roomselect' && this.shared.checkInState !== 'quickclick')
        this.shared.checkInState = 'roomselect';
    }
    if (this.router.url.indexOf('/confirm') !== -1) {
      this.shared.checkInState = 'confirmation';
    }

    this.is_admin_general = this.router.url.indexOf('/admin/general') !== -1;

    this.admin = this.authService.isAdmin();
    this.agent = this.authService.isAgent();

    if (this.admin || this.agent) {
      this.show_mode = this.shared.loadMode(this.authService);
    }

  }


  startLogOutPolling() {
    const that = this;
    this.sharedSubjectSubscription = TimerObservable.create(0, 500)
      .pipe(takeWhile(() => that.alive))
      .subscribe(() => {

        if (!that.shared.loggedIn)
          that.logOut();
      });
  }

  startUploadPolling() {
    const that = this;

    if (this.timerSubscription)
      this.timerSubscription.unsubscribe();

    this.timerSubscription = TimerObservable.create(0, that.interval)
      .pipe(takeWhile(() => that.alive))
      .subscribe(() => {
        that.pollUploadStatus();
      });
  }

  pollUploadStatus() {
    if (!this.lastUpsellConf)
      return;

    if (this.lastUpsellConf.upload_status == 'Q') {
      this.updateRecentUpsell();
    }
  }

  clearRecentUpsell(): void {
    this.lastUpsellConf = undefined;
  }

  updateRecentUpsell(): void {
    const that = this;
    this.lastUpsellConf = undefined;
    this.reporting.getAgentActivityRecent(this.shared.selectedHotel, this.shared.mode_current).pipe(
      finalize(() => {
        this.isLoading = false;
      })).subscribe(
      data => {
        if (data && data.length > 0) {

          let temp = data[0];


          let tax = 1 + (temp.tax_rate ? Number(temp.tax_rate.room_upsell_tax_rate) : 0);

          temp.priceNt = !temp.ofr_ovrd_price ? Number(temp.ofr_price) : Number(temp.ofr_ovrd_price);
          temp.totalNt = temp.priceNt * tax;
          temp.total = temp.priceNt * tax * temp.los;

          that.lastUpsellConf = temp;

        } else {
          that.lastUpsellConf = undefined;
        }

      },
      error => {
      });
  }






  confirmationCopy(evt: any, confNum: string, tripDropDown: boolean = false) {
    const textArea = document.createElement('textarea');
    textArea.value = confNum;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';


    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);


    if (tripDropDown) {
      evt.stopPropagation();
      if ($('.dropdown').find('.dropdown-menu').is(":hidden")){
        (<any>$('.dropdown-toggle')).dropdown('toggle');
      }
    }

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
    } catch (err) {
    }

    document.body.removeChild(textArea);
  }


  mode = {'fd_user' : 'checkin', 'cc_user' : 'callcenter', 'd_user' : 'direct'}
  view = {'checkin' : 'reservation', 'callcenter' : 'roomselect', 'direct' : 'roomselect'}

  switchMode(event) {
    this.shared.reset(true);
    this.shared.mode_current = event.id;
    this.shared.currentMode = event.id;
    const view = this.mode[this.shared.mode_current];

    this.shared.currentAgentView = view;
    this.shared.checkInState = this.view[view];

    if (!this.is_admin_route) {
      this.updateRecentUpsell();

      this.navigateAgentView();
    } else {
      const isReporting = this.router.url.indexOf('/admin/reporting') !== -1;

      if (isReporting) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/admin/reporting']);
      }
    }

  }


  changeAgentView(view: string) {
    
    this.shared.currentAgentView = view;

    switch (view) {
      case 'checkin':
      case 'addon':
        this.shared.checkInState = 'reservation';
        break;
      case 'callcenter':
      case 'direct':        
        this.shared.checkInState = 'roomselect';
        break;
    }

    this.navigateAgentView();
  }

  navView(view: string): void {

    switch (view) {
      case 'direct':
      case 'admin':
        const adminUrl = this.authService.firstAdminRoute();
        this.router.navigate([adminUrl]);

        break;
      case 'agent':
        switch (this.shared.currentAgentView) {
          case 'checkin':
            this.navView('reservation');
            break;
          case 'callcenter':
          case 'prearrival':
          case 'addon':
            this.navView('roomselect');
            break;
          default:
            const view = this.shared.mode_current === 'fd_user' ? 'checkin' : 'callcenter';
            this.shared.currentAgentView = view;
            this.navView('agent');
            break;
        }
        break;
      case 'reservation':
      case 'confirmation':
      case 'roomselect':
      case 'quickclick':
        this.shared.reset(false);
        this.shared.checkInState = view;
        this.navigateAgentView();

        break;

    }
  }

  navigateAgentView() {
    
    switch (this.shared.currentAgentView) {
      case 'activity':
        this.router.navigate(['/activity']);
        break;
      case 'stats':
        this.router.navigate(['/stats']);
        break;
      case 'teamstats':
        this.router.navigate(['/teamstats']);
        break;
      case 'checkin':
        switch (this.shared.checkInState) {
          case 'reservation':
            this.router.navigate(['/reservation', this.shared.selectedHotel]);
            break;
          case 'confirmation':
            this.router.navigate(['/confirm']);
            break;
          default:
            this.router.navigate(['generic', this.shared.selectedHotel]);
            break;
        }
        /*if (!this.shared.show_res_conf)
          this.router.navigate(['generic', this.shared.selectedHotel]);
        else
          this.router.navigate(['/confirm']);*/
        break;
      case 'callcenter': 
      case 'addon':
      case 'prearrival':
        switch (this.shared.checkInState) {
          case 'reservation':
            this.router.navigate(['/reservation', this.shared.selectedHotel]);
            break;          
          default:
            this.router.navigate([this.shared.currentAgentView, this.shared.selectedHotel]);

           /* if (this.shared.currentAgentView == 'callcenter') {
              this.router.navigate(['callcenter', this.shared.selectedHotel]);
            } else {
              this.router.navigate(['prearrival', this.shared.selectedHotel]);
            }*/

            break;
        }
        break;
      case 'direct':
        const adminUrl = this.authService.firstAdminRoute();
        this.router.navigate([adminUrl]);
        break;         
    }
  }



  userChangePassword() {
    this.changePasswordSelect.handleClearClick();    
    this.shared.currentAgentView = 'checkin';
    this.username = this.shared.username;
    this.router.navigate(['/profile']);

  }


  updatePromo(usePromo: boolean) {
    this.isLoading = true;
    this.shared.usePromo = usePromo;
    const that = this;
    setTimeout(function () {
      that.isLoading = false;
    }, 100);

    if(!usePromo){

      switch (this.shared.checkInState) {
        case 'reservation':
          this.navView('reservation');
          break;
        case 'confirmation':
          this.navView('confirmation');
          break;
        case 'roomselect':
          this.navView('roomselect');
          break;
        case 'quickclick':
          this.navView('quickclick');
          break;      
      }


    }
  }


  clickedBreadcrumb(event) {
    this.router.navigate([event.target.href]);
  }

  clickedHotel(event: any) {

    const curMode = this.shared.mode_current;
    this.shared.reset(true);
    this.shared.mode_current = curMode;

    this.shared.selectedHotel = event.id;

    this.navigateToHome();
  }

  navigateToHome(){

    if (this.is_admin_route) {
      this.navView('admin');
    } else {
      setTimeout(() => {
        switch (this.shared.mode_current) {
          case 'fd_user':
            this.shared.currentAgentView = 'checkin';
            this.router.navigate(['reservation', this.shared.selectedHotel], {replaceUrl: true});
            break;
          case 'cc_user':
            this.shared.currentAgentView = 'callcenter';
            this.router.navigate(['callcenter', this.shared.selectedHotel], {replaceUrl: true});
            break;
          case 'd_user':
              this.shared.currentAgentView = 'direct';
              this.router.navigate(['direct', this.shared.selectedHotel], {replaceUrl: true});
            break;
        }

      });

    }

  }


  manuallyUpdate() {
    this.confirmManuallyUpdatedModalReference = this.modalService.open(this.confirmManuallyUpdatedModal, {
      backdrop: 'static',
    });
  }

  manuallyUpdateConfirm() {
    const that = this;

    this.reporting.updateOfferProcessedManually(this.lastUpsellConf.prop_id, this.lastUpsellConf.offer_set_id, true).pipe(
      finalize(() => {
        if (that.confirmManuallyUpdatedModalReference) {
          that.confirmManuallyUpdatedModalReference.close();
        }
        that.updateRecentUpsell();
      })).subscribe(
      data => {
      },
      error => {
      });
  }

  logOut() {
    localStorage.clear();
    this.shared.reset(true);
    this.shared.mode_current = 'fd_user';
    this.shared.currentAgentView = 'checkin';
    this.router.navigate(['/'], {replaceUrl: true})
      .then(() => {
        window.location.reload();
      });
  }

}

