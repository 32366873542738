import {Pipe, PipeTransform} from '@angular/core';
@Pipe({name: 'offerSearchFilter'})
export class OfferSearchFilter implements PipeTransform {
  public transform(items: any[], search: string, className: string, bedType: string[], roomTag: string[]): any[] {
    if (!items) {
      return [];
    }

    let filterItems = items;
    if (search != undefined && search != '') {
      search = search.toUpperCase();

      const searchItems = search.split(/[\s,]+/);

      /* filterItems = [];
       for (var s = 0; s < searchItems.length; s++) {
         const filter =  items.filter(i =>
           i.upsell_room_bed_type.toUpperCase().includes(searchItems[s]) ||
           i.upsell_room_class_cd.toUpperCase().includes(searchItems[s]) ||
           i.upsell_room_category_cd.toUpperCase().includes(searchItems[s]) ||
           i.upsell_room_category_name.toUpperCase().includes(searchItems[s])
         );
         filterItems = filterItems.concat(filter);
       }*/

      filterItems = items.filter(i =>
        searchItems.every(el => i.upsell_room_bed_type.toUpperCase().includes(el)) ||
        searchItems.every(el => i.upsell_room_class_cd.toUpperCase().includes(el)) ||
        searchItems.every(el => i.upsell_room_category_cd.toUpperCase().includes(el)) ||
        searchItems.every(el => i.upsell_room_category_name.toUpperCase().includes(el))
      );



    }

    if (className != undefined && className != '' && className != 'ALL') {
      filterItems = filterItems.filter(i =>
        i.upsell_room_class_cd.includes(className)
      );
    }

    if (bedType != undefined && bedType[0] != 'ALL') {
      
      let tempFilter = [];
      
      // LEGACY CODE FOR OR STATEMENT
      // bedType.forEach(t => {
      //   tempFilter = tempFilter.concat(filterItems.filter(i =>
      //     i.upsell_room_bed_type.includes(t)
      //   ));
      // });


      let bedTypes = bedType as any[]
      filterItems.forEach(t => {
        let upsell_room_bed_type = t.upsell_room_bed_type as any[]    
        if(upsell_room_bed_type === null){
          upsell_room_bed_type = [];
        }            
        tempFilter = tempFilter.concat(
          bedTypes.every(val => upsell_room_bed_type.includes(val)) ? t : null
        );
      });

      tempFilter = tempFilter.filter(val => !!val);
      filterItems = Object.assign([], tempFilter);
    
    }


    if (roomTag != undefined && roomTag[0] != 'ALL') {
      let tempFilter = [];
      
      
      // LEGACY CODE FOR OR STATEMENT
      // roomTag.forEach(t => {
      //   tempFilter = tempFilter.concat(filterItems.filter(i =>
      //     i.upsell_room_tags ? i.upsell_room_tags.filter(i2 => i2.includes(t)).length > 0 : false
      //   ));
      // });

      // AND STATEMENT
      let roomTags = roomTag as any[]
      filterItems.forEach(t => {
        let upsell_room_tags = t.upsell_room_tags as any[] 
        if(upsell_room_tags === null){
          upsell_room_tags = [];
        }         
        tempFilter = tempFilter.concat(
          roomTags.every(val => upsell_room_tags.includes(val)) ? t : null
        );
      });

      tempFilter = tempFilter.filter(val => !!val);
      filterItems = Object.assign([], tempFilter);

    }

    // item dedup
    filterItems = filterItems.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === filterItems.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    });
    return filterItems;
  }
}

