import {Injectable} from '@angular/core';
import { AuthService } from './auth_service';

@Injectable()
export class Shared {

  private _loggedIn: boolean = false;

  // public availableHotels = [];
  public availableClasses = [];
  // public selectedHotel;
  public selectedClass;
  public selectedRoomType;
  public selectedLengthOfStay;
  public selectedSourceCodeLetter;
  public selectedSourceCode;
  public sourceCodes = [];
  public firstLetters = [];
  // public username: string;
  public last_url;


  // confirmation, reservation, roomselect, quickclick


  // public show_res_conf;
  public current_row: number;
  public current_column: number;
  public config = [];
  public origConfig = [];
  public current_answers;
  public path = {};
  public params = {};
  public tree;
  public flat = [];
  public bread = [];
  public storedGenericRoutes = false;
  public usePromo = false;
  public promoCode = '';
  // public quickClickEnabled = false;
  public quickClickData;
  public current_page: number;

  public flgAutopilot = true;

  public genericRouteEvents = [];


  public offersAvailable = true;

  public mode_show = false;
  public mode_current = '';
  public currentAgentView = '';
  public checkInState = '';

  constructor() {
    this.current_row = 0;
    this.current_column = 0;
    this.current_page = 0;

    this.setInitialView();

  }

  setInitialView(): void {
    this.mode_current = this.defaultMode;
    switch (this.mode_current) {
      case 'fd_user':
        this.currentAgentView = 'checkin';
        this.checkInState = 'reservation';
        break;
      case 'cc_user':
        this.currentAgentView = 'callcenter';
        this.checkInState = 'roomselect';
        break;
      case 'd_user':
          this.currentAgentView = 'callcenter';
          this.checkInState = 'roomselect';
          break;
    }
  }

  get loggedIn(): boolean {
    return !this.username ? false : true;
  }


  get username(): string {
    return localStorage.getItem('username');
  }

  set username(_username: string) {
    localStorage.setItem('username', _username);
  }

  get selectedHotel(): string {
    return localStorage.getItem('selectedHotel');
  }

  set selectedHotel(_selectedHotel: string) {
    localStorage.setItem('selectedHotel', _selectedHotel);
  }

  get availableHotels(): Array<any> {
    return JSON.parse(localStorage.getItem('availableHotels'));
  }

  set availableHotels(_availableHotels: Array<any>) {
    localStorage.setItem('availableHotels', JSON.stringify(_availableHotels));
  }

  
  get currentMode(): string {
    return localStorage.getItem('currentMode');
  }

  set currentMode(_currentMode: string) {
    localStorage.setItem('currentMode', _currentMode);
  }


  get availableModes(): Array<any> {
    return JSON.parse(localStorage.getItem('availableModes'));
  }

  set availableModes(_availableModes: Array<any>) {
    localStorage.setItem('availableModes', JSON.stringify(_availableModes));
  }

  modeNames = {
    "fd_user": "Front Desk", 
    "cc_user": "Call Center", 
    "d_user": "Direct",  
  }

  public getModeName(mode: string){
    let key = mode as keyof typeof this.modeNames;
    return this.modeNames[key];
  }


  get defaultMode(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    const modes = user ? user['mode'] : {};

    let arr = []
    arr.push(['fd_user' , modes['fd_user'] || false ]);
    arr.push(['cc_user' , modes['cc_user'] || false ]);
    arr.push(['d_user' , modes['d_user'] || false ]);

    const user_modes = arr.filter(u => u[1]);
    this.availableModes = user_modes;

    if(this.currentMode && this.currentMode.length !== 0) {      
      return this.currentMode;
    } else {
    return user_modes.length !== 0 ? user_modes[0][0] : 'cc_user';
    }

  }

  loadMode(authService: AuthService):boolean {
    
    const mode_fd = authService.hasMode('fd_user');
    const mode_cc = authService.hasMode('cc_user');
    const mode_d = authService.hasMode('d_user');    
    
    let modes = [mode_fd, mode_cc, mode_d].filter(v => v).length;

    if(modes >= 2){
      return true;
    }

    return false;
  }


  reset(all: boolean) {
    this.availableClasses = [];
    this.selectedClass = void 0;
    this.selectedRoomType = void 0;
    this.selectedLengthOfStay = void 0;
    this.selectedSourceCodeLetter = void 0;
    this.selectedSourceCode = void 0;
    this.sourceCodes = [];
    this.firstLetters = [];
    this.current_row = 0;
    this.current_column = 0;
    this.path = {};
    this.params = {};
    this.tree = void 0;
    this.flat = [];
    this.bread = [];
    this.storedGenericRoutes = false;

    this.promoCode = '';
    this.current_page = 0;

    if (all) {
      this.usePromo = false;
      this.setInitialView();

      this.offersAvailable = true;
    }
  }

  requestStatusNames = {
    "E": "Pending", 
    "Q": "Pending", 
    "R": "Rejected", 
    "S": "Accepted", 
    "M": "Accepted", 
  }

  public requestStatusName(requestStatusCode: string): string 
  {

    let key = requestStatusCode as keyof typeof this.requestStatusNames;
    return this.requestStatusNames[key];
  }

}
