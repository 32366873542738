import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'userSearchFilter'})
export class UserSearchFilter implements PipeTransform {
  public transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }

    let filterItems = items;
    if (search != undefined && search != '') {
      search = search.toUpperCase();
      const searchItems = search.split(/[\s,]+/);
      filterItems = items.filter(i =>
        searchItems.every(el => i.user_name.toUpperCase().includes(el)) ||
        searchItems.every(el => i.user_fname.toUpperCase().includes(el)) ||
        searchItems.every(el => i.user_lname.toUpperCase().includes(el))
      );
    }

    return filterItems;
  }
}
