
<ng-template #confirmManuallyUpdatedModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Manually Updated?</h4>
  </div>
  <div class="modal-body">
    Do you wish to mark this upgrade as manually updated?
  </div>
  <div class="modal-footer">
    <div class="col-md-4 col-xs-4 col-sm-4"></div>
    <div class="col-md-4 col-xs-4 col-sm-4">
      <button type="button" class="btn btn-danger btn-block" (click)="modal.dismiss()">Cancel</button>
    </div>
    <div class="col-md-4 col-xs-4 col-sm-4">
      <button type="button" class="btn btn-primary btn-block" (click)="manuallyUpdateConfirm()">Confirm</button>
    </div>

  </div>
</ng-template>


<div [class.container]="is_frontend_route || is_guest_route" [class.containerBig]="is_admin_route" id="main-ccontainer">

  <div class="row top-buffer" *ngIf="this.shared.username && this.shared.selectedHotel">
    <nav class="navbar navbar-default" style="background-color: #222222; border: unset;" >
      <div class="container-fluid" >
        <div class="navbar-header">
          <div class="navbar-brand"><!--LuxSell-->
            <img class="lp-logo-nav" src="assets/img/Logo_Luxsell_RGB.png">
          </div>
        </div>

        <!-- <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" *ngIf="show_hotel_dropdown"> -->
        <div [hidden]="this.router.url === '/hotels'" class="disable-text-selection">
          <ng-select 
            appendTo="body" 
            [items]="this.hotels"
            [virtualScroll]="true"
            bindLabel="text" 
            bindValue="id"
            [(ngModel)]="this.shared.selectedHotel"
            class="nav navbar-nav custom-nav"
            style="margin-top: 6px;"
            [clearable]="false"
            [searchable]="false"
            (change)="clickedHotel($event)"
            > 
          </ng-select>   

          <ng-select 
            appendTo="body" 
            [items]="modeList" 
            [virtualScroll]="true"
            bindLabel="text" 
            bindValue="id"
            [(ngModel)]="this.shared.mode_current"
            class="nav navbar-nav custom-nav"
            style="margin-top: 6px;"
            [clearable]="false"
            [searchable]="false"
            (change)="switchMode($event)"
            >
          </ng-select>    

          <ul class="nav navbar-nav navbar-right">
            <li *ngIf="agent && admin && this.shared.mode_current !== 'd_user'" [ngClass]="{'active': is_frontend_route}" style="margin-right: -10px;"><a (click)="navView('agent')">Agent</a></li>
            <li *ngIf="agent && admin" [ngClass]="{'active': is_admin_route}"><a (click)="navView('admin')">Admin</a></li>
            <li>

              <ul class="nav navbar-nav" style="padding-top: 0;">

                <ng-select
                
                #changePassword
                appendTo="body" 
                [items]="changePasswordItems"
                [virtualScroll]="false"
                bindLabel="text" 
                bindValue="id"
                [ngClass]="{'hide-arrow': this.hideArrow, 'nav': true, 'navbar-nav': true, 'custom-nav': true}"
                [placeholder]="this.username"
                class="nav navbar-nav custom-nav"
                style="margin-top: 6px; margin-right: 12px;"
                [clearable]="false"
                [searchable]="false"
                (change)="userChangePassword()"
                > 
              </ng-select>                    

              </ul>
            </li>
            <li>
              <a class="ng-binding glyphicon glyphicon-log-out" style="margin-top:-1px; margin-left: -20px;" (click)="logOut()"> </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div *ngIf="show_path_panel && this.shared.username && this.shared.selectedHotel">
    <div class="row col-md-12 disable-text-selection">
      <nav class="navbar navbar-default" style="border-width: 0px;margin-bottom: 0;font-size: 1.2em;">
        <ul class="nav navbar-nav">
          <li><a (click)="this.changeAgentView('callcenter')" class="ng-scope" style="cursor:pointer;"
                 [ngClass]="{'active': this.shared.currentAgentView == 'callcenter'}"
                 *ngIf="this.shared.mode_current === 'cc_user'">CALL CENTER</a></li>
          <li><a (click)="this.changeAgentView('checkin')" class="ng-scope" style="cursor:pointer;"
                 [ngClass]="{'active': this.shared.currentAgentView == 'checkin'}"
                 *ngIf="this.shared.mode_current === 'fd_user' && this.authService.hasAccess('AGENT_UPSELL', false)">CHECKIN</a></li>
          <li><a (click)="this.changeAgentView('addon')" class="ng-scope" style="cursor:pointer;"
                 [ngClass]="{'active': this.shared.currentAgentView == 'addon'}"
                 *ngIf="this.authService.hasAccess('AGENT_ADDON', false)">ADDON</a></li>

          <li><a (click)="this.changeAgentView('prearrival')" class="ng-scope" style="cursor:pointer;"
                 [ngClass]="{'active': this.shared.currentAgentView == 'prearrival'}"
                 *ngIf="this.authService.hasAccess('AGENT_FUTURE', false) && this.shared.mode_current === 'fd_user'">FUTURE STAY</a></li>

          <li><a (click)="this.changeAgentView('activity')" class="ng-scope" style="cursor:pointer;" [ngClass]="{'active': this.shared.currentAgentView == 'activity'}">MY ACTIVITY</a></li>
          
          <li><a (click)="this.changeAgentView('stats')" class="ng-scope" style="cursor:pointer;" [ngClass]="{'active': this.shared.currentAgentView == 'stats'}">MY STATS</a></li>
          <li><a *ngIf="this.authService.hasAccess('AGENT_TEAMSTATS', false)"
                 (click)="this.changeAgentView('teamstats')" class="ng-scope" style="cursor:pointer;" [ngClass]="{'active': this.shared.currentAgentView == 'teamstats'}">TEAM STATS</a></li>
        </ul>
      </nav>
    </div>

    <div *ngIf="this.shared.currentAgentView === 'checkin' && this.shared.offersAvailable">
      <div class="pull-left disable-text-selection" style="padding-top: 12px; padding-right: 10px;">
        <a (click)="navView('reservation')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'reservation'}">Res Search</a>
        <a (click)="navView('confirmation')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'confirmation'}">Confirmation</a>
        <a (click)="navView('roomselect')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'roomselect'}">Room Selection</a>
        <a (click)="navView('quickclick')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'quickclick'}">Quick Click</a>
      </div>
      <div class="col-md-5 pull-right disable-text-selection">
        <ul class="list-inline text-right">
          <li class="h5 text-uppercase" ng-switch="spc.confSearch !== true">
            <a *ngIf="this.shared.usePromo" (click)="updatePromo(false)" class="ng-scope" style="cursor:pointer;">Standard</a>
            <span *ngIf="!this.shared.usePromo" class="ng-scope">Standard</span>
          </li>
          <li class="h5 text-uppercase" ng-switch="spc.confSearch === true">
            <a *ngIf="!this.shared.usePromo" (click)="updatePromo(true)" class="ng-scope" style="cursor:pointer;">Promo</a>
            <span *ngIf="this.shared.usePromo" class="ng-scope">Promo</span>
          </li>
        </ul>
      </div>
    </div>


    <div class="row" style="padding-bottom: 16px; margin: 0;" 
    
    *ngIf="this.shared.currentAgentView === 'callcenter'
          || this.shared.currentAgentView === 'prearrival'
          || this.shared.currentAgentView === 'addon'">
    
    <div class="pull-left" style="padding-top: 12px; padding-right: 10px;">
        <a (click)="navView('reservation')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'reservation'}">Res Search</a>
        <a (click)="navView('roomselect')" class="ng-scope" style="cursor:pointer;font-weight: 700; margin-right: 25px;" [ngClass]="{'active': shared.checkInState === 'roomselect'}">New Res</a>
      </div>
      <div class="col-md-5 pull-right">
        <ul class="list-inline text-right">
          <li class="h5 text-uppercase" ng-switch="spc.confSearch !== true">
            <a *ngIf="this.shared.usePromo" (click)="updatePromo(false)" class="ng-scope" style="cursor:pointer;">Standard</a>
            <span *ngIf="!this.shared.usePromo" class="ng-scope">Standard</span>
          </li>
          <li class="h5 text-uppercase" ng-switch="spc.confSearch === true">
            <a *ngIf="!this.shared.usePromo" (click)="updatePromo(true)" class="ng-scope" style="cursor:pointer;">Promo</a>
            <span *ngIf="this.shared.usePromo" class="ng-scope">Promo</span>
          </li>
        </ul>
      </div>
    </div>


    <div class="clearfix"></div>
  </div>
  <div [hidden]="isLoading">
    <router-outlet (activate)="updateDropDowns()"></router-outlet>
  </div>
  <div class="col-md-6 col-md-push-3" [hidden]="!isLoading" style="padding-top:100px;text-align:center;">
    <span style="font-size:80px;" class="glyphicon glyphicon-refresh glyphicon-spin"></span>
    <div style="padding-top:10px;">
      Loading...
    </div>
  </div>
</div>

<div class="container" id="prior-res-info" *ngIf="show_path_panel && this.shared.username && this.shared.selectedHotel && lastUpsellConf && (this.shared.currentAgentView === 'checkin' || this.shared.currentAgentView === 'callcenter' || this.shared.currentAgentView === 'prearrival' || this.shared.currentAgentView === 'addon') ">
  <div class="panel">
    <div class="panel-heading" [class.h6]="true">
      <h3 class="panel-title ng-binding">

        <div style="font-size: 16px;font-weight: bold;">
          <span class="field-title">LAST UPSELL:</span>

          <span id="confNumField" style="cursor: pointer; color: #2a9fd6; padding-left: 4px;" (click)="confirmationCopy($event, lastUpsellConf.conf_num)" title="Copy to Clipboard">{{lastUpsellConf.conf_num}}</span>
          <ng-container *ngIf="lastUpsellConf.conf_num && lastUpsellConf.upload_status && lastUpsellConf.upload_status != 'N'">
            <div class="glyphicon glyphicon-alert" style="font-size: 1.2rem;margin-left: 4px; color: red;"
                 [dropdownTriggerFor]="dropdown"
                 *ngIf="lastUpsellConf.upload_status == 'E'"></div>
            <div class="glyphicon glyphicon-upload" style="font-size: 1.2rem;margin-left: 4px; color: yellow;"
                 *ngIf="lastUpsellConf.upload_status == 'Q'"></div>
            <div class="glyphicon glyphicon-ok-circle" style="font-size: 1.2rem;margin-left: 4px; color: green;"
                 *ngIf="lastUpsellConf.upload_status == 'S'"></div>
            <div class="glyphicon glyphicon-ok-sign" style="font-size: 1.2rem;margin-left: 4px; color: green;"
                 *ngIf="lastUpsellConf.upload_status == 'M'"></div>

            <my-dropdown #dropdown>
              <div (click)="manuallyUpdateConfirm()" class="m-dropdown-item">Mark as Manually Updated</div>
            </my-dropdown>

          </ng-container>
          <span class="field-title">RT:</span>
          {{lastUpsellConf.base_room_category_cd}} -> {{lastUpsellConf.upsell_room_category_cd}}
          <span class="field-title">AMT: </span>
          <!-- "dropdown" -->
          <span ngbDropdown style="display: inline-block !important">

            <a ngbDropdownToggle aria-expanded="false">${{(!lastUpsellConf.ofr_ovrd_price ? lastUpsellConf.ofr_price : lastUpsellConf.ofr_ovrd_price) | number:'1.2-2'}}</a>
                        
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <ul ngbDropdownItem>

                <li class='dropdown-item'>

                  <div class="col col-md-12">
                    <div style="color: white;">PER NIGHT</div>
                    <div class="row" style="margin: 6px 0;color: #888;">
                      <span>Offer:</span>
                      <span style="float: right; margin-left: 2px;">
                        <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                              title="Copy to Clipboard"
                              (click)="confirmationCopy($event, lastUpsellConf.priceNt.toFixed(2), true)"></span></span>
                      <span style="float: right;">${{lastUpsellConf.priceNt.toFixed(2) }}</span>
                    </div>
                    <div class="row" style="margin: 6px 0;color: #888;">
                      <span>Tax:</span>
                      <span style="float: right; margin-left: 2px;">
                        <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                              title="Copy to Clipboard"
                              (click)="confirmationCopy($event, (lastUpsellConf.totalNt - lastUpsellConf.priceNt).toFixed(2), true)"></span></span>
                      <span style="float: right;">${{(lastUpsellConf.totalNt - lastUpsellConf.priceNt).toFixed(2) }}</span>
                    </div>
                    <div class="row" style="margin: 6px 0;color: #888;">
                      <span>Total:</span>
                      <span style="float: right; margin-left: 2px;">
                        <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                              title="Copy to Clipboard"
                              (click)="confirmationCopy($event, lastUpsellConf.totalNt.toFixed(2), true)"></span></span>
                      <span style="float: right;">${{lastUpsellConf.totalNt.toFixed(2) }}</span>
                      </div>


                  </div>
                </li>
                <li class='dropdown-item'>

                    <div class="col col-md-12">
                      <div style="color: white;">TRIP</div>
                      <div class="row" style="margin: 6px 0;color: #888;">
                        <span>Offer:</span>
                        <span style="float: right; margin-left: 2px;">
                          <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                                title="Copy to Clipboard"
                                (click)="confirmationCopy($event, (lastUpsellConf.priceNt * lastUpsellConf.los).toFixed(2), true)"></span></span>
                        <span style="float: right;">${{(lastUpsellConf.priceNt * lastUpsellConf.los).toFixed(2) }}</span>
                      </div>
                      <div class="row" style="margin: 6px 0;color: #888;">
                        <span>Tax:</span>
                        <span style="float: right; margin-left: 2px;">
                          <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                                title="Copy to Clipboard"
                                (click)="confirmationCopy($event, (lastUpsellConf.total - (lastUpsellConf.priceNt * lastUpsellConf.los)).toFixed(2), true)"></span></span>
                        <span style="float: right;">${{(lastUpsellConf.total - (lastUpsellConf.priceNt * lastUpsellConf.los)).toFixed(2) }}</span>
                      </div>
                      <div class="row" style="margin: 6px 0;color: #888;">
                        <span>Total:</span>
                        <span style="float: right; margin-left: 2px;">
                          <span class="glyphicon glyphicon-copy" style="font-size: 1.1rem;margin-left: 4px;cursor: pointer;color: #2a9fd6;"
                                title="Copy to Clipboard"
                                (click)="confirmationCopy($event, lastUpsellConf.total.toFixed(2), true)"></span></span>
                        <span style="float: right;">${{lastUpsellConf.total.toFixed(2) }}</span>
                      </div>

                    </div>

                </li>
              </ul>
            </div>
          </span>



          <span class="field-title" *ngIf="lastUpsellConf.guest_lname && lastUpsellConf.guest_fname">GUEST:</span>
          <span *ngIf="lastUpsellConf.guest_lname && lastUpsellConf.guest_fname">
                <span style="padding-left: 4px;">{{lastUpsellConf.guest_lname}}</span>, <span class="name">{{lastUpsellConf.guest_fname}}</span>
          </span>
        </div>

        <a class="ng-binding res-close glyphicon glyphicon-remove" (click)="clearRecentUpsell()"> </a>
      </h3>
    </div>
  </div>
</div>


<div class="footer" *ngIf="show_path_panel && this.shared.username && this.shared.selectedHotel">
  <div class="container">
    LuxSell is a product of <a href="http://www.luxepricing.com/">Luxe Pricing</a>.  For support, please contact <a href="mailto:support@luxepricing.com?Subject=Support" target="_top">support&#64;luxepricing.com</a>
  </div>
</div>
