import { JsonPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbAlertModule, NgbCalendar, NgbDateStruct, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [NgbDatepickerModule, NgbAlertModule, FormsModule, JsonPipe],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  styles: ['h1 { font-weight: normal; }']
})
export class DatePickerComponent {

  // constructor(public authService: AuthService) {
  // }

  today = inject(NgbCalendar).getToday();

	model: NgbDateStruct;
	date: { year: number; month: number };


  // onDateChanged(event: any): void {

  //   if (event.singleDate && event.singleDate.jsDate) {
  //     this.promo.dt_expire = event.singleDate.date.month + '/' + event.singleDate.date.day + '/' + event.singleDate.date.year;
  //   } else {
  //     this.promo.dt_expire = null;
  //   }
  //   this.save();
  // }


}
