import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {config} from '../config';
import { environment } from '../../environments/environment';

@Injectable()
export class Httpclient {

  private domain: string;
  private _url: string;

  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
    this.domain = environment.domain;
    this._url = this.domain + 'hotel/';
  }


  getSystemStatus(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
    headers.append('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));

    const url = this.domain + 'account/status';

    return this.intercept(this.http.get(url, {headers}).pipe(map(res => res)));
  }

  postLogin(url, data): Observable<any> {
    return this.getSystemStatus().pipe(mergeMap(sys => {
      if (sys.online) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
        headers.append('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));
        return this.intercept(this.http.post(url, data, {headers}).pipe(map(res => res)));
      } else {
        // route to maintenaince screen
        this.router.navigate(['/maintenance']);
        return of(true);
      }
    }));
  }

  get(url): Observable<any> {
    /* return this.getSystemStatus().pipe(mergeMap(sys => {
       if (sys.online) {*/
    // return this.getFinal(url);
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'))
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));
    //headers.set('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));

    return this.intercept(this.http.get(url, {headers}).pipe(map(res => res)));
  }

  post(url, data): Observable<any> {
    /*  return this.getSystemStatus().pipe(mergeMap(sys => {
        if (sys.online) {*/
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'))
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));

      return this.intercept(this.http.post(url, data, {headers}).pipe(map(res => res)));
  }

  put(url, data): Observable<any> {
    /* return this.getSystemStatus().pipe(mergeMap(sys => {
       if (sys.online) {*/
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'))
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));

    return this.intercept(this.http.put(url, data, {headers}).pipe(map(res => res)));
  }

  delete(url): Observable<any> {
    /* return this.getSystemStatus().pipe(mergeMap(sys => {
       if (sys.online) {*/
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'))
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN'));

    return this.intercept(this.http.delete(url, {headers}).pipe(map(res => res)));
  }


  intercept(observable: Observable<any>) {
    return observable.pipe(catchError(err => this.handleErr(err)));
  }

  handleErr(err) {
    console.log(err);
    if (err.status === 401) {
      return this.unauthorised();

    } else if (err.status === 403) {
      return this.forbidden();
    } else {
      return observableThrowError(err);
    }
  }

  unauthorised(): Observable<any> {
    localStorage.clear();
    this.router.navigate(['/']);
    return of(true);
  }

  forbidden(): Observable<any> {
    localStorage.clear();
    this.router.navigate(['/']);
    return of(true);
  }

  checkAuthorised(): void {
    /* if (!this.token.token.length)
     {
       this.router.navigate(['login']);
     }*/
  }
}
